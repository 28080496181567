<template style="height: 100%; background-color: #f0f2f6">
  <div id="body_div" style="background-color: white; position: relative;height: 100%;overflow-y: hidden">
    <div style="height: 80%;overflow-y: hidden">
      <div style="padding: 20px 20px;height:5%;border-bottom: 1px solid #EAECEF;">
        <img style="display: inline-block;height: 24px;vertical-align: middle" src="../assets/img/robot/phone_back.png" @click="goBack()">
        <span style="font-size: 18px;color: #000613;font-weight: 500;display: inline-block;vertical-align: middle;margin-left: 10px">{{ $t('footer.ul_li1') }}</span>
      </div>
      <div id="message-content" style="height: 85%; overflow: scroll; -webkit-overflow-scrolling: touch" ref="viewBox">
        <div style="font-size: 14px" v-for="(item, i) in messageList">
          <div style="position:relative;padding:0 20px">
            <span v-if="i== 0 || (i != 0 && (item.create_this_time-messageList[i-1].create_this_time)>1000*60*60*2)" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #72777E">{{ item.create_time }}</span>
            <div v-if="item.type =='user'" style="width: 100%; text-align: -webkit-right;margin-top: 10px">
              <div style="display: inline-block;width: 76%">
                <div v-if="item.content != 'pic_info'" style="display: block;max-width: 100%;width: fit-content;background-color: #E0EBFF;padding: 15px;border-radius: 10px">
                  <span style="display:block; width:100%; text-align: left;word-break: break-all;color: #1E2329" v-html="item.content"></span>
                </div>
                <img v-if="item.picture" style="display:block;width: 100px;margin-top: 10px;border-radius: 10px" :src="item.picture_small" @click="preview(item.picture)">
              </div>
              <img v-if="user_header" style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" :src="user_header">
              <img v-else style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;margin-left: 6px;display: inline-block;vertical-align: top" src="../assets/img/avatar.png">
            </div>
<!--            <span v-if="item.update_time" style="display: block; width: 100%; text-align: center;margin-top:10px;font-size: 12px;color: #72777E">{{ item.update_time }}</span>-->
            <div v-if="item.type == 'service'" style="display:block; width:100%; text-align: left;margin-top: 10px">
              <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../assets/img/msg_header.jpg">
              <div style="display: inline-block;width: 76%">
                <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329">{{ item.content?item.content=='^^^&&&***###'?'你好！我是规划师，很高兴为你服务。请用一两句话简述你的问题。':item.content:'' }}</span>
                <img v-if="item.replyPictureList" style="display: block;width: 100px;margin-top: 10px; border-radius: 10px;" v-for="items in item.replyPictureList" :src="items + '/small'" @click="preview(items)">
              </div>
            </div>
<!--            <span v-if="i == messageList.length -1 && !item.reply" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #72777E">感谢您的反馈，我们将于1个工作日内回复您！</span>-->
<!--            <text v-if="i == messageList.length -1 && showTimeNoOnline == true" style="display: block; width: 100%; text-align: center;margin-top: 10px;font-size: 12px;color: #ffffff">{{$t('index.Customer_not_online')}}</text>-->
            <div v-if="(messageList.length == 0 || i == messageList.length -1) && showTimeNoOnline == true" style="display:block; width:100%; text-align: left;margin-top: 10px">
              <img style="width: 35px;border: #AB54DB 1px solid; border-radius: 50%;display: inline-block;margin-right: 6px;vertical-align: top" src="../assets/img/msg_header.jpg">
                <div style="display: inline-block;width: 76%">
                  <span style="display: block;vertical-align: top;word-break: break-all;width: fit-content;background-color: #f7f7f7;padding: 15px;border-radius: 10px;color: #1E2329;-webkit-user-select: text; user-select: text;">客服上班时间是：
                    ☀️上午09：00-12:00
                    🌙下午13：00-18:00

                    有任何问题您可以在这个时间段联系我们🌷~
                    非在班时间的话可以先留言，上班后也会第一时间回复您的呢❤️
                    祝您身体健康，工作顺利，财源滚滚👍</span>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div style="height: 20%;position:relative;">
<!--      <div style="width: 100%;padding: 8px 0;border-bottom: #EAECEF 1px solid; margin-bottom: 10px;">-->
<!--        <el-radio style="margin: 5px 0px 5px 20px" v-model="selectValue" label="投诉" border size="medium">投诉</el-radio>-->
<!--        <el-radio v-model="selectValue" label="建议" border size="medium">建议</el-radio>-->
<!--        <el-radio v-model="selectValue" label="故障" border size="medium">故障</el-radio>-->
<!--        <el-radio v-model="selectValue" label="其他" border size="medium">其他</el-radio>-->
<!--      </div>-->
      <div style="position:relative; border-top: #EAECEF 1px solid;">
        <div style="display:inline-block;vertical-align: middle">
          <el-upload
              class="avatar-uploader"
              :action="upload"
              name="pic"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview">
            <div class="" style="text-align: center;">
              <img style="width: 30px; height: 28px; text-align: center" src="../assets/img/msg_pic.svg">
            </div>
          </el-upload>
        </div>
        <el-input style="display:inline-block;font-size: 14px;border: unset;width: 59%;vertical-align: middle;" :autosize="{ minRows: 1, maxRows: 3 }" type="textarea" :placeholder="$t('robot.enter_content')" v-model="feedbackContent" @input="totalinput($event)"></el-input>
        <el-button class="submit-btn" type="primary" style="" @click="submit()">{{ $t('footer.send') }}</el-button>
      </div>
    </div>
    <el-image-viewer class="viewer" v-if="imgsVisible" :urlList="[showImgUrl]" :on-close="closeViewer"></el-image-viewer>
  </div>
<!--  <div style="width: 100%; height: 100%">-->
<!--    <div style="height: 5%; display: flex; align-items: center; position:relative; text-align: center; width: 100%">-->
<!--      <div style="position: absolute; left: 15px; color: #666666; font-size: 14px;" class="el-icon-arrow-left" @click="goBack()">{{ $t('center.go_back') }}</div>-->
<!--      <div style="font-size: 14px; margin: 0 auto">在线客服</div>-->
<!--    </div>-->
<!--    <iframe style="height: 95%; width: 100%;" :src="url" name="obj" frameborder="0"></iframe>-->
<!--  </div>-->

</template>

<script>
import {feedbackRequest, getFeedbackMsg} from "../server/auth";
import {baseUrl} from "../utils/request";
// 可自行去对应目录查看该组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "messageBack",
  data() {
    return {
      selectValue: '其他',
      feedbackContent: '',
      imageUrl: '',
      thisNum: 0,
      totalNum: 500,
      upload: baseUrl + '/other/uploadImage',
      messageList: [],
      messageList_linshi: [],
      imgsVisible: false,
      showImgUrl: '',
      userInfo: {},
      user_header: '',
      pageNum: 1,
      pageSize: 10,
      totalPage: 1,
      timer: null, // 定时器
      msgHeight: 0,
      url: 'https://chatbot.aliyuncs.com/intl/index.htm?from=4qOUAx1INE',
      uid: '',
      token: '',
      lastTime: '',
      firstTime: '',
      showTimeNoOnline: false, // 为true时 提示客服不在线
      timeInterval: null,
      box:{}
    }
  },
  components: {
    ElImageViewer
  },

  watch:{

  },

  created() {

  },
  mounted() {
    // document.getElementById('body_div').addEventListener('touchmove', function (e) {
    //   e.preventDefault() // 阻止默认的处理方式(阻止下拉滑动的效果)
    // }, {passive: false}) // passive 参数不能省略，用来兼容ios和android
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(this.userInfo){
      this.user_header = this.userInfo.user_face?this.userInfo.user_face + '/head':''
    } else {
      this.user_header = ''
    }

    this.token = localStorage.getItem('token')
    // this.getMessageInfo();
    this.scrollListener()

    var that = this
    if(!this.token){
      const fpPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.onload = resolve
        script.onerror = reject
        script.async = true
        script.src = 'https://cdn.jsdelivr.net/npm/' + '@fingerprintjs/fingerprintjs@3/dist/fp.min.js'
        document.head.appendChild(script)
      }).then(() => FingerprintJS.load())
      // Get the visitor identifier when you need it.
      fpPromise.then(fp => fp.get()).then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId
        console.log("test>>>>>>:"+visitorId)
        this.uid = visitorId
        that.getMessageInfo();
      })
      // uni.getSystemInfo({
      // 	success(e) {
      // 		console.log('系统信息', e.deviceId)
      // 		that.uid = e.deviceId
      // 	}
      // })
    } else {
      this.getMessageInfo();
    }
    this.timeInterval = setInterval(()=>{
      this.pageNum = 1
      if(this.messageList.length > 0){
        this.lastTime = this.messageList[this.messageList.length - 1].time
        this.firstTime = ''
      } else {
        this.lastTime = ''
      }
      this.getMessageInfo(10, 'Refresh')
      // this.gotoBottom()
    }, 20000)
    if(new Date().getHours()>18 || new Date().getHours()<9){
      this.showTimeNoOnline = true
      console.log('asdqweqwasdasd', this.showTimeNoOnline)
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    totalinput(value) {
      if (value.length > 500) {
        this.feedbackContent = value.substr(0, 500)
        this.$message.warning('最多只能輸入500個字符')
      }
      this.thisNum = this.feedbackContent.length
    },

    scrollListener:function(){
      this.box = this.$refs.viewBox
      this.box.addEventListener('scroll', this.addListener, false)
    },

    addListener(){
      if (this.$refs.viewBox.scrollTop == 0){
        // this.pageNum++
        if(this.messageList.length > 0){
          this.firstTime = this.messageList[0].time
          this.lastTime = ''
        } else {
          this.firstTime = ''
        }
        this.getMessageInfo(10)
      }
    },

    async submit(content) {
      if (this.token){
        this.uid = ''
      }
      if (!this.selectValue) {
        this.$message.warning('请选择问题类型')
        return
      }
      if (!this.feedbackContent) {
        this.$message.warning('请输入问题描述')
        return
      }
      if(this.$util.filterText(this.feedbackContent)){
        this.$message.warning('包含敏感词汇，请重新编辑！')
        return
      }
      const res = await feedbackRequest(this.selectValue, this.feedbackContent, this.imageUrl, this.uid)
      if (res.success) {
        // if (!content){
        //   let info = {}
        //   info.content = this.feedbackContent.replaceAll('\n', '<br/>')
        //   info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
        //   info.create_this_time = new Date().getTime()
        //   this.messageList.push(info)
        // }
        if(this.messageList.length > 0){
          this.lastTime = this.messageList[this.messageList.length - 1].time
          this.firstTime = ''
        } else {
          this.lastTime = ''
        }
        await this.getMessageInfo(10, 'Refresh', 'addMessage')
        // this.selectValue='';
        this.feedbackContent='';
        this.imageUrl='';
        // this.scrollBottom()
        // await this.getMessageInfo(10);
      } else {
        this.$message.error(res.message);
      }

    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
      this.feedbackContent = 'pic_info'
      var info = {}
      info.picture = this.imageUrl
      info.picture_small = this.imageUrl+'/small'
      info.content = this.feedbackContent
      info.create_time = this.$util.formatDateTime(this.$util.formatDate_dd(new Date().getTime(),'/'))
      info.create_this_time = new Date().getTime()
      // this.messageList.push(info)
      this.submit('pic_info')
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;

      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt12M) {
        this.$message.error('图片大小不能超过 12MB!');
      }
      return (isPNG || isJPG) && isLt12M;
    },
    handlePictureCardPreview(file, fileList) {
      console.log(12)
    },
    async getMessageInfo(requestTimes, Refresh, addMessage){
      if (this.pageNum > this.totalPage){
        return
      }
      const res = await getFeedbackMsg(requestTimes, this.pageNum, this.pageSize, this.uid, this.lastTime, this.firstTime)
      if (res) {
        if (res.success) {
          if(!Refresh && !this.firstTime && !this.lastTime){
            this.totalPage = res.data.totalPage>0?res.data.totalPage:1
          }
          this.messageList_linshi = res.data.list
          for (let i = 0; i < this.messageList_linshi.length; i++) {
            this.messageList_linshi[i].content = this.messageList_linshi[i].content.replace('\n', '<br/>')
            this.messageList_linshi[i].create_this_time = this.$util.formatUnix(this.messageList_linshi[i].time)
            // this.messageList_linshi[i].update_this_time = this.$util.formatUnix(this.messageList_linshi[i].update_time)
            this.messageList_linshi[i].create_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            if (this.messageList_linshi[i].time) {
              this.messageList_linshi[i].update_time = this.$util.formatDateTime(this.messageList_linshi[i].time)
            }
            if (this.messageList_linshi[i].picture) {
              this.messageList_linshi[i].picture_small = this.messageList_linshi[i].picture + '/small'
            }

            if (this.messageList_linshi[i].picture && this.messageList_linshi[i].type == 'service'){
              this.messageList_linshi[i].replyPictureList = this.messageList_linshi[i].picture.split(',')
            }
          }
          // this.messageList = this.messageList_linshi.concat(this.messageList)
          if(Refresh || (addMessage && addMessage == 'addMessage')){
            this.messageList = this.messageList.concat(this.messageList_linshi)
          } else {
            this.messageList = this.messageList_linshi.concat(this.messageList)
          }

          if(Refresh || (!this.firstTime && !this.lastTime)){
            if(this.messageList.length > 0){
              this.lastTime = this.messageList[this.messageList.length - 1].time
            }
            this.scrollBottom()
          }

        } else {
          this.$message.warning(res.message)
        }
      }
    },

    scrollBottom(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          if (div){
            div.scrollTop = div.scrollHeight; // 将聊天框滚轮拉到最底部
            this.msgHeight = div.scrollHeight
          }
          window.clearInterval(that.timer)
        }
      }, 800)
    },

    scrollCenter(){
      const that = this
      that.timer = setInterval(function () {
        // console.log(document.readyState)
        if (document.readyState === 'complete') {
          let div = document.getElementById('message-content');
          div.scrollTop = div.scrollHeight-this.msgHeight; // 将聊天框滚轮拉到最底部
          this.msgHeight = div.scrollHeight
          window.clearInterval(that.timer)
        }
      }, 500)
    },

    preview(view){
      this.imgsVisible = true
      this.showImgUrl = view
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__img");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    closeViewer(){
      this.imgsVisible = false
    }
  },

  beforeDestroy() {
    if(this.timeInterval){
      clearInterval(this.timeInterval)
      this.timeInterval = null
    }
    this.box.removeEventListener('scroll', this.addListener(), false)
    window.clearInterval(this.timer)
    clearInterval(this.timer)
    this.timer = null
  },

  destroyed() {
  }
}
</script>

<style scoped>

.submit-btn {
  position: absolute;
  display: inline-block;
  background-color: #AB54DB;
  right: 20px;
  top: 0;
  height: 40px;
  bottom: 0;
  margin: auto 0;
  vertical-align: middle;
}

>>> .el-radio__input {
  display: none;
  border-radius: 36px;
}

.is-checked {
  color: white;
  border: none;
  background-color: #AB54DB;
}

.el-radio--medium.is-bordered {
  padding: 0px;
  border-radius: 36px;
  height: 23px;
  margin: 0px auto;
  vertical-align: middle;
  width: 12%;
  text-align: center;
}

>>>.el-radio__label {
  font-size: 14px;
  vertical-align: middle;
  line-height: 23px;
  padding-left: 0px;
}

>>> .el-radio__input.is-checked + .el-radio__label {
  color: white;
}

.avatar-uploader {
  margin: 8px 15px 8px 20px;
  width: 28px;
  height: 28px;
  /*border: 1px #EAECEF solid;*/
  border-radius: 1px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar {
  width: 50px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: block;
}

>>>.el-textarea__inner{
  border: unset;
  resize: none;
  padding: 5px 15px 5px 0 !important;
}
.preview_dialog >>>.el-dialog{
  /*background-color: transparent;*/
}

.viewer >>>.el-image-viewer__close{
  opacity: 1;
  top: 70px;
  right: 26px;
}
@media screen and (max-width: 1079px){
  .sm{
    display: block;
    height: 100% !important;
    background-color: #f0f2f6;
  }
}




</style>
