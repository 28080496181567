import { render, staticRenderFns } from "./messageBack.vue?vue&type=template&id=3d11dd82&scoped=true&style=height%3A%20100%25%3B%20background-color%3A%20%23f0f2f6&"
import script from "./messageBack.vue?vue&type=script&lang=js&"
export * from "./messageBack.vue?vue&type=script&lang=js&"
import style0 from "./messageBack.vue?vue&type=style&index=0&id=3d11dd82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d11dd82",
  null
  
)

export default component.exports